<!--
 * @Author: zhaoyang
 * @Date: 2023-06-28 19:55:46
 * @Last Modified by: egafrandika
 * @Last Modified time: 2024-08-01 11:38:10
-->

<template>
    <div class="who">
        <div class="who-box ec-container">
            <slot />
            <div class="who-left">
                <div class="title">
                    <span>{{ getI18n('title') }}</span>
                </div>
                <div class="desc">
                    {{ getI18n('desc') }}
                </div>
            </div>
            <div class="who-right">
                <div
                    v-for="(item, index) in displayCardList"
                    :key="index"
                    class="item-box"
                >
                    <div class="money">
                        {{ item.value }}
                    </div>
                    <div class="money-desc">
                        {{ item.header }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getLocal from 'easycash/common/mixin/get-local';
import prefetch from 'easycash/common/mixin/server-prefetch';
import ConfigNew from 'easycash/common/resource/config-new';

const ApiDataList = [7, 8, 9, 4, 6];

export default {
    name: 'WhoAreWe',

    mixins: [prefetch('home'), getLocal],

    data() {
        return {
            isPlay: false
        };
    },

    computed: {
        displayCardList() {
            const {home: {operationData} = {}} = this;
            if (!operationData) return [];

            return ApiDataList.map(item => operationData?.[item]);
        }
    },

    watch: {
        '$i18n.locale' () {
            this.requestData();
        }
    },

    methods: {
        async initFetch() {
            const params = {
                keys: 'app_config.idn_statistics_data',
                language: this.getLocal()
            };
            const {data: {body: {
                configs: [{configValue}]
            }}} = await ConfigNew.generalConfig({params, hideLoading: true});

            return {operationData: configValue};
        },

        getI18n(key, params) {
            return this.$t(`homeNew.whoAreWe.${key}`, params);
        }
    }
};
</script>

<style lang="scss" scoped>
.who {
    color: #000;
    border-radius: 0.5rem 0.5rem 0 0;
    box-sizing: border-box;
    padding: 1rem 0 1.9rem;
    background-image: url("./img/bg.png"), linear-gradient(163deg, #f8f8f8 -0.08%, #e3eaf3 90.71%);
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;

    @media screen and (max-width: $screen-md) {
        border-radius: 0.24rem 0.24rem 0 0;
        padding: 0.4rem 0 0.6rem;
        background-image: linear-gradient(to top, #fff 0%, #e3eaf3 100%);
        margin-top: -0.24rem;
    }

    .who-box {
        display: flex;
        justify-content: space-between;
        position: relative;

        @media screen and (max-width: $screen-md) {
            flex-direction: column;
        }

        .who-left {
            box-sizing: border-box;
            padding-right: 0.8rem;
            width: 40%;
            min-width: 40%;

            @media screen and (max-width: $screen-md) {
                min-width: unset;
                width: 100%;
                padding-right: 0;
                margin-bottom: 0.2rem;
            }

            .title {
                display: inline;
                font-size: 0.56rem;
                font-weight: 700;
                line-height: 0.8rem;
                position: relative;
                margin-bottom: 0.1rem;

                @media screen and (max-width: $screen-md) {
                    font-size: 0.24rem;
                    line-height: 1;
                }
            }

            .desc {
                font-size: 0.16rem;
                line-height: 0.24rem;

                @media screen and (max-width: $screen-md) {
                    font-size: 0.16rem;
                    margin-top: 0.1rem;
                    font-weight: 400;
                    line-height: 0.24rem;
                }
            }
        }

        .who-right {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            @media screen and (max-width: $screen-md) {
                justify-content: unset;
                flex-direction: column;
            }

            .item-box {
                box-sizing: border-box;
                padding: 0.4rem 0.3rem 0.2rem;
                width: 2.8rem;
                height: 1.8rem;
                border-radius: 0.32rem;
                background: linear-gradient(145deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 0.01%, rgba(255, 255, 255, 0.7) 100%);
                backdrop-filter: blur(0.2rem);
                margin-left: 0.1rem;
                margin-bottom: 0.1rem;

                &:hover {
                    background: #58d36e;

                    .money,
                    .money-desc {
                        color: #fff;
                    }
                }

                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                    width: 2.2rem;
                }

                &:nth-child(5) {
                    margin-right: 0.55rem;
                }

                @media screen and (max-width: $screen-md) {
                    padding: 0.2rem;
                    width: 100%;
                    height: 1.3rem;
                    border-radius: 0.16rem;
                    margin-bottom: 0.1rem;
                    margin-left: 0;

                    &:hover {
                        background: linear-gradient(145deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 0.01%, rgba(255, 255, 255, 0.7) 100%);

                        .money {
                            color: #1db839;
                        }

                        .money-desc {
                            color: #000;
                        }
                    }

                    &:first-child,
                    &:nth-child(2),
                    &:nth-child(3) {
                        width: 100%;
                    }

                    &:nth-child(4) {
                        margin-right: 0;
                    }
                }

                .money {
                    color: #1db839;
                    font-size: 0.3rem;
                    font-weight: 700;
                }

                .money-desc {
                    margin-top: 0.05rem;
                    font-size: 0.16rem;
                    line-height: 0.2rem;
                }
            }
        }
    }
}
</style>
