var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "why" }, [
    _c("div", { staticClass: "why-box ec-container" }, [
      _c("div", { staticClass: "why-title" }, [
        _vm._v("\n            " + _vm._s(_vm.getI18n("title")) + "\n        "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "choose-box" },
        [
          _vm._l(_vm.chooseList, function (item, index) {
            return _c("div", { key: index, staticClass: "choose-item" }, [
              _c("img", {
                staticClass: "choose-icon",
                attrs: { src: item.icon },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "choose-content-wrap" }, [
                _c("div", { staticClass: "choose-title" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(item.title) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "choose-desc" }, [
                  _vm._v(
                    "\n                        " +
                      _vm._s(item.desc) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                index !== 4 ? _c("div", { staticClass: "border-r" }) : _vm._e(),
              ]),
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "robot" }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "feature-box ec-container" }, [
      _c("div", { staticClass: "why-title" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.getI18n("featureTitle")) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "feature-wrap feature-wrap-left" }, [
        _c("div", { staticClass: "feature-text" }, [
          _c("div", { staticClass: "feature-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getI18n("featureOneTitle")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature-content" }, [
            _c(
              "div",
              { staticClass: "left" },
              _vm._l(_vm.getI18n("featureContentLeft"), function (item) {
                return _c("div", { key: item.title, staticClass: "box" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(item.title) +
                        "\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(item.text) +
                        "\n                            "
                    ),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _vm._l(_vm.getI18n("featureContentRight"), function (item) {
                  return _c("div", { key: item.title, staticClass: "box" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(item.title) +
                          "\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(item.text) +
                          "\n                            "
                      ),
                    ]),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("borrow")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.getI18n("more")) +
                        "\n                        "
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "feature-wrap feature-wrap-right" }, [
        _c("div", { staticClass: "feature-text feature-text-bottom" }, [
          _c("div", { staticClass: "feature-title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getI18n("featureTwoTitle")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "feature-content feature-content-bottom" }, [
            _c(
              "div",
              { staticClass: "left left-bottom" },
              _vm._l(_vm.getI18n("featureContentBottomLeft"), function (item) {
                return _c("div", { key: item.title, staticClass: "box" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(item.title) +
                        "\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "\n                                " +
                        _vm._s(item.text) +
                        "\n                            "
                    ),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right right-bottom" },
              [
                _vm._l(
                  _vm.getI18n("featureContentBottomRight"),
                  function (item) {
                    return _c("div", { key: item.title, staticClass: "box" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(item.title) +
                            "\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(
                          "\n                                " +
                            _vm._s(item.text) +
                            "\n                            "
                        ),
                      ]),
                    ])
                  }
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "more",
                    on: {
                      click: function ($event) {
                        return _vm.goPage("lend")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.getI18n("more")) +
                        "\n                        "
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }