<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-6-25 15:48:46 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2023-11-13 16:39:16 -->

<template>
    <div class="home">
        <home-banner />
        <beware-of-scams class="d-none d-md-block" />
        <six-th class="d-none d-md-block" />
        <who-are-we>
            <beware-of-scams class="mb-show" />
            <six-th class="mb-show" />
        </who-are-we>
        <why-choose-us />
        <home-user />
        <home-activity :blog-list="blogList" />
        <partners />
    </div>
</template>

<script>
import Partners from '../component/partners.vue';
import BewareOfScams from './component/beware-of-scams';
import HomeActivity from './component/home-activity.vue';
import HomeBanner from './component/home-banner';
import HomeUser from './component/home-user';
import SixTh from './component/six-th';
import WhoAreWe from './component/who-are-we';
import WhyChooseUs from './component/why-choose-us';

export default {
    name: 'Home',

    components: {
        WhoAreWe,
        SixTh,
        WhyChooseUs,
        HomeBanner,
        HomeUser,
        BewareOfScams,
        Partners,
        HomeActivity
    },

    props: {
        blogList: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style lang="scss" scoped>
.mb-show {
    @media screen and (min-width: $screen-md) {
        opacity: 0;
        width: 0;
        height: 0;
    }
}
</style>
