var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "home-banner-pc d-none d-md-block" },
      [
        _c(
          "swiper",
          {
            ref: "mySwiper",
            staticStyle: { "z-index": "2" },
            attrs: { options: _vm.swiperOptions },
          },
          [
            _c("swiper-slide", [
              _c("div", { staticClass: "slide-box bg-green-black" }, [
                _c(
                  "div",
                  { staticClass: "slide" },
                  [
                    _c(
                      "ec-image",
                      { attrs: { webp: require("./img/1.webp") } },
                      [
                        _c("img", {
                          staticClass: "banner-one",
                          attrs: { src: require("./img/1.jpg") },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("swiper-slide", [
              _c("div", { staticClass: "slide-box bg-gray-black" }, [
                _c(
                  "div",
                  { staticClass: "slide" },
                  [
                    _c(
                      "ec-image",
                      { attrs: { webp: require("./img/2.webp") } },
                      [
                        _c("img", {
                          staticClass: "banner-one",
                          attrs: { src: require("./img/2.jpg") },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("swiper-slide", [
              _c("div", { staticClass: "slide-box bg-green-black" }, [
                _c(
                  "div",
                  { staticClass: "slide" },
                  [
                    _c(
                      "ec-image",
                      { attrs: { webp: require("./img/3.webp") } },
                      [
                        _c("img", {
                          staticClass: "banner-one",
                          attrs: { src: require("./img/3.jpg") },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("swiper-slide", [
              _c("div", { staticClass: "slide-box bg-gray-black" }, [
                _c(
                  "div",
                  { staticClass: "slide" },
                  [
                    _c(
                      "ec-image",
                      { attrs: { webp: require("./img/4.webp") } },
                      [
                        _c("img", {
                          staticClass: "banner-one",
                          attrs: { src: require("./img/4.jpg") },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "content-box ec-container" }, [
                _vm.realIndex === 0
                  ? _c("div", { staticClass: "my-poa" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sub-poa",
                          class: {
                            "sub-poa-active": _vm.needDh,
                          },
                        },
                        [
                          _c("div", { staticClass: "content-title" }, [
                            _c("span", [_vm._v(_vm._s(_vm.getI18n("bt1")))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "por" }, [
                              _c("img", {
                                staticClass: "content-icon",
                                staticStyle: { right: "0.4rem", top: "0.4rem" },
                                attrs: {
                                  src: require("../../component/img/shield-aman.png"),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _c("span", [_vm._v(_vm._s(_vm.getI18n("desc")))]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c("app-store-button", { attrs: { type: "video" } }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner1", type: "google" },
                          }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner1", type: "apple" },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.realIndex === 1
                  ? _c("div", { staticClass: "my-poa" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sub-poa",
                          class: {
                            "sub-poa-active": _vm.needDh,
                          },
                        },
                        [
                          _c("div", { staticClass: "content-title" }, [
                            _c("span", [_vm._v(_vm._s(_vm.getI18n("bt2")))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "por" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.getI18n("btt2")) +
                                  "\n                                    "
                              ),
                              _c("img", {
                                staticClass: "content-icon",
                                attrs: {
                                  src: require("../../component/img/shield-aman.png"),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _c("span", [_vm._v(_vm._s(_vm.getI18n("desc")))]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c("app-store-button", { attrs: { type: "video" } }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner2", type: "google" },
                          }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner2", type: "apple" },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.realIndex === 2
                  ? _c("div", { staticClass: "my-poa" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sub-poa",
                          class: {
                            "sub-poa-active": _vm.needDh,
                          },
                        },
                        [
                          _c("div", { staticClass: "content-title" }, [
                            _c("span", [_vm._v(_vm._s(_vm.getI18n("bt3")))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "por" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.getI18n("btt3")) +
                                  "\n                                    "
                              ),
                              _c("img", {
                                staticClass: "content-icon",
                                attrs: {
                                  src: require("../../component/img/shield-aman.png"),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _c("span", [_vm._v(_vm._s(_vm.getI18n("desc")))]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c("app-store-button", { attrs: { type: "video" } }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner3", type: "google" },
                          }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner3", type: "apple" },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.realIndex === 3
                  ? _c("div", { staticClass: "my-poa" }, [
                      _c(
                        "div",
                        {
                          staticClass: "sub-poa",
                          class: {
                            "sub-poa-active": _vm.needDh,
                          },
                        },
                        [
                          _c("div", { staticClass: "content-title" }, [
                            _c("span", [_vm._v(_vm._s(_vm.getI18n("bt4")))]),
                            _vm._v(" "),
                            _c("span", { staticClass: "por" }, [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.getI18n("btt4")) +
                                  "\n                                    "
                              ),
                              _c("img", {
                                staticClass: "content-icon",
                                attrs: {
                                  src: require("../../component/img/shield-aman.png"),
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "text" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getI18n("desc4")) + " "),
                              _c(
                                "a",
                                {
                                  staticClass: "click",
                                  on: {
                                    click: function ($event) {
                                      return _vm.goPage("faqBeware")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.getI18n("click")))]
                              ),
                              _vm._v(" " + _vm._s(_vm.getI18n("desc4-1"))),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c("app-store-button", { attrs: { type: "video" } }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner3", type: "google" },
                          }),
                          _vm._v(" "),
                          _c("app-store-button", {
                            attrs: { "unique-id": "Banner3", type: "apple" },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", {
              staticClass: "swiper-pagination",
              attrs: { slot: "pagination" },
              slot: "pagination",
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "home-banner-mobile d-block d-md-none" }, [
      _c("img", {
        staticClass: "banner-mobile",
        attrs: { src: require("./img/banner-mb.jpg") },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "content-wrap" }, [
        _c("div", { staticClass: "por" }, [
          _c("div", { staticClass: "content-title-mobile" }, [
            _c("span", [_vm._v(_vm._s(_vm.getI18n("bt1")))]),
          ]),
          _vm._v(" "),
          _c("img", {
            staticClass: "icon",
            attrs: { src: require("../../component/img/shield-aman.png") },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "content-desc" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.getI18n("desc")) +
              "\n            "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "btn-group ec-container",
            style: {
              justifyContent:
                _vm.$app.isIOS || _vm.$app.isAndroid
                  ? "center"
                  : "space-between",
            },
          },
          [
            !_vm.$app.isIOS
              ? _c("app-store-button", { attrs: { type: "google" } })
              : _vm._e(),
            _vm._v(" "),
            !_vm.$app.isAndroid
              ? _c("app-store-button", { attrs: { type: "apple" } })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }