var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "who" }, [
    _c(
      "div",
      { staticClass: "who-box ec-container" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("div", { staticClass: "who-left" }, [
          _c("div", { staticClass: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.getI18n("title")))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "desc" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.getI18n("desc")) +
                "\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "who-right" },
          _vm._l(_vm.displayCardList, function (item, index) {
            return _c("div", { key: index, staticClass: "item-box" }, [
              _c("div", { staticClass: "money" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(item.value) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "money-desc" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(item.header) +
                    "\n                "
                ),
              ]),
            ])
          }),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }