<!--
 * @Author: zhaoyang
 * @Date: 2023-11-09 12:49:55
 * @Last Modified by: kenneth
 * @Last Modified time: 2024-03-22 13:36:36
-->

<template>
    <div>
        <div class="six-th d-none d-md-block">
            <div class="six-th-box ec-container">
                <div class="video-wrap">
                    <video
                        ref="video"
                        class="video-box"
                        src="https://ec-cdn.fintopia.tech/upload/admin/lgkMlyv6T0NMPqX0N88TmYiAFvdf.mp4"
                        controls
                    />
                    <template v-if="!isPlay">
                        <img
                            src="./img/ph.png"
                            class="ph"
                        >
                        <img
                            src="./img/play.svg"
                            class="play"
                            @click="playVideo"
                        >
                    </template>
                </div>
                <div class="text-wrap">
                    <img
                        src="./img/6th.svg"
                        class="text-title"
                    >
                    <div class="title">
                        {{ getI18n('title') }}
                    </div>
                    <div class="text">
                        {{ getI18n('tip') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="th6">
            <img
                src="./img/6th.svg"
                class="text-title"
            >
            <div class="title">
                {{ getI18n('title') }}
            </div>
            <div class="text-th6">
                {{ getI18n('tip') }}
            </div>
            <div class="video-wrap">
                <video
                    v-show="isPlay"
                    ref="videoMb"
                    class="video-box"
                    src="https://ec-cdn.fintopia.tech/upload/admin/lgkMlyv6T0NMPqX0N88TmYiAFvdf.mp4"
                    controls
                />
                <template v-if="!isPlay">
                    <img
                        src="./img/ph.png"
                        class="ph"
                    >
                    <img
                        src="./img/play.svg"
                        class="play"
                        @click="playVideo"
                    >
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SixTh',

    data() {
        return {
            isPlay: false,
            isMobile: false
        };
    },

    mounted() {
        this.isMobile = window.innerWidth <= 768;

        window.addEventListener('resize', this.calcMobile);

        this.$nextTick(() => {
            if (this.$refs.video) {
                this.$refs.video.load();
            }
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.calcMobile);
    },

    methods: {
        calcMobile() {
            this.isMobile = window.innerWidth <= 768;
        },

        getI18n(key, params) {
            return this.$t(`homeNew.6th.${key}`, params);
        },

        playVideo() {
            this.isPlay = true;

            this.$nextTick(() => {
                if (!this.isMobile) {
                    this.$refs.video.play();

                    return;
                }

                this.$refs.videoMb.play();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.six-th {
    position: relative;
    z-index: 3;
    margin-top: -1rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #fff;

    .six-th-box {
        box-sizing: border-box;
        padding-top: 1rem;
        padding-bottom: 1.25rem;
        display: flex;

        .video-wrap {
            width: 7.12rem;
            min-width: 7.12rem;
            height: 4rem;
            border-radius: 0.32rem;
            overflow: hidden;
            position: relative;

            .video-box {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            .ph {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .play {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 0.6rem;
                height: 0.6rem;
            }
        }

        .text-wrap {
            margin-top: 0.8rem;
            margin-left: 0.5rem;

            .title {
                color: #1db839;
                margin-top: 0.2rem;
                font-family: Mozaic HUM;
                font-size: 0.32rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.32px;
            }

            .text-title {
                width: 2.24rem;
                height: 1.02rem;
            }

            .text {
                margin-top: 0.1rem;
                color: #000;
                font-family: Mozaic HUM;
                font-size: 0.16rem;
                font-style: normal;
                font-weight: 400;
                line-height: 0.24rem;
            }
        }
    }
}

.th6 {
    @media screen and (min-width: $screen-md) {
        opacity: 0;
        height: 0;
    }

    .title {
        margin-top: 0.2rem;
        color: #1db839;
        font-family: Mozaic HUM;
        font-size: 0.32rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.32px;
    }

    .text-title {
        width: 0.7rem;
        height: 0.32rem;
    }

    .text-th6 {
        margin-top: 0.05rem;
        color: #000;
        font-family: Mozaic HUM;
        font-size: 0.16rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.24rem;
    }

    .video-wrap {
        height: 1.88rem;
        margin-top: 0.1rem;
        margin-bottom: 0.32rem;
        overflow: hidden;
        border-radius: 0.16rem;
        position: relative;

        .video-box {
            width: 100%;
            height: 100%;
        }

        .ph {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

        .play {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0.6rem;
            height: 0.6rem;
        }
    }
}
</style>
