var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "beware-of-scams d-none d-md-block" }, [
      _c("div", { staticClass: "beware-of-scams-box ec-container" }, [
        _c("div", { staticClass: "text-wrap" }, [
          _c("img", {
            staticClass: "text-title",
            attrs: { src: require("./img/robot-beware.png") },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getI18n("title")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getI18n("tip")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.getI18n("security")) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "more",
              on: {
                click: function ($event) {
                  return _vm.goPage("faqBeware")
                },
              },
            },
            [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.getI18n("more")) +
                  "\n                "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "video-wrap" },
          [
            _c("video", {
              ref: "video",
              staticClass: "video-box",
              attrs: { src: _vm.showWhatsappLink, controls: "" },
            }),
            _vm._v(" "),
            !_vm.isPlay
              ? [
                  _c("img", {
                    staticClass: "ph",
                    attrs: {
                      src: require("./img/beware-of-scams-thumbnail.png"),
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "play",
                    attrs: { src: require("./img/play.svg") },
                    on: { click: _vm.playVideo },
                  }),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "beware-of-scams-mb" }, [
      _c("img", {
        staticClass: "text-title",
        attrs: { src: require("./img/robot-beware.png") },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n            " + _vm._s(_vm.getI18n("title")) + "\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-th6" }, [
        _vm._v("\n            " + _vm._s(_vm.getI18n("tip")) + "\n        "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-th6" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.getI18n("security")) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "video-wrap" },
        [
          _c("video", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPlay,
                expression: "isPlay",
              },
            ],
            ref: "videoMb",
            staticClass: "video-box",
            attrs: { src: _vm.showWhatsappLink, controls: "" },
          }),
          _vm._v(" "),
          !_vm.isPlay
            ? [
                _c("img", {
                  staticClass: "ph",
                  attrs: {
                    src: require("./img/beware-of-scams-thumbnail.png"),
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "play",
                  attrs: { src: require("./img/play.svg") },
                  on: { click: _vm.playVideo },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }