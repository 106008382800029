/*
 * @Author: chengyuzhang
 * @Date: 2022-04-21 19:02:02
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-06-16 15:58:43
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    generalConfig: {
        url: '/api/generalConfig',
        method: 'get'
    },

    getStaticTextByKey: {
        url: '/api/operation/staticText/getByKey',
        method: 'get'
    }
};

export default Resource.create(api);
